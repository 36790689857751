<style lang="less" scoped>
@import "./MyOrder.less";
</style>

<template>
  <div>
    <Icon type="ios-alert-outline" />
    <span>未付款的课程请复制“订单号”联系顾问完成付款</span>
    <Table
      id="orders-table"
      :columns="orderColumns"
      :data="orderData"
      :loading="loading"
    >
      <template slot-scope="{ row }" slot="action">
        <Button
          v-if="!row.paid"
          type="error"
          size="small"
          @click="deleteOrder(row)"
          >删除</Button
        >
        <div v-else>暂无操作</div>
      </template>
    </Table>
  </div>
</template>

<script>
import { Message } from "view-design";
export default {
  data() {
    return {
      loading: true,
      orderColumns: [
        {
          title: "服务项目",
          key: "course_code",
          minWidth: 200,
          renderHeader(h, { column }) {
            // console.log(column)
            return <div style="padding-left: 25px">{column.title}</div>;
          },
          render(h, { row }) {
            return <div style="padding-left: 25px">{row.course_code}</div>;
          }
        },
        {
          title: "学校",
          key: "campus",
          minWidth: 200
        },
        {
          title: "订单号",
          key: "transaction_id",
          minWidth: 250,
          render(h, { row }) {
            const { transaction_id } = row;
            return (
              <div>
                <span id={transaction_id} style="margin-right: 10px;">
                  {transaction_id}
                </span>
                <Button
                  size="small"
                  style="color: #4c8bf5;"
                  onclick={function () {
                    const el = document.createElement("textarea");
                    el.value = transaction_id;
                    document.body.appendChild(el);
                    el.select();
                    document.execCommand("copy");
                    document.body.removeChild(el);
                    Message.success("订单号已复制");
                    // this.$Message.success('订单号已复制');
                  }}
                >
                  <Icon type="ios-copy" />
                </Button>
              </div>
            );
          }
        },
        {
          title: "状态",
          key: "paid",
          align: "center",
          minWidth: 100,
          maxWidth: 200,
          className: "column-price",
          renderHeader(h, { column }) {
            // console.log(column)
            return <div>{column.title}</div>;
          },
          render(h, { row }) {
            return (
              <div>
                {row.paid ? (
                  <Tag color="success">已付款</Tag>
                ) : (
                  <Tag color="warning">未付款</Tag>
                )}
              </div>
            );
          }
        },
        {
          title: "管理报名",
          slot: "action",
          align: "center",
          minWidth: 200
        }
      ],
      orderData: []
    };
  },
  methods: {
    deleteOrder(row) {
      const displayName =
        this.$store.getters.getCurrentUser.display_name || " ";
      this.$Modal.confirm({
        title: `<h2>Dear user ${displayName}</h2>`,
        content: `<div>Are you sure to delete the ${row.course_code} service?</div>
        `,
        onCancel: () => {
          this.$Modal.remove();
        },
        onOk: async () => {
          const { student, course_code } = row;
          try {
            const resp = await this.axios.delete(
              `api/student/${student}/course/${course_code}`
            );
            if (resp.status === 200) {
              this.$Message.success(
                `恭喜${displayName}!您已经成功删除了${row.course_code}服务!`
              );
            }
            this.axios.get("api/courses/signed").then(resp => {
              // console.log(resp.data)
              this.orderData = resp.data;
            });
          } catch {
            this.$Message.error("删除服务失败");
          } finally {
            this.$Modal.remove();
          }
        }
      });
    }
  },
  mounted: function () {
    this.axios.get("api/courses/signed").then(resp => {
      // console.log(resp.data)
      this.orderData = resp.data;
      this.loading = false;
    });
  }
};
</script>
